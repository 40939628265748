import { gql } from 'apollo-boost'

export const QueryControlType = gql`
  query ControlType {
    controlType
  }
`

export const MutationControlTypeSave = gql`
  mutation ControlTypeSave($controlType: ControlTypeEnum!) {
    controlTypeSave(controlType: $controlType) {
      type
      writeOps
      op
    }
  }
`
