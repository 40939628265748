import { gql } from 'apollo-boost'

export const SubscriptionDateTime = gql`
  subscription DateTime {
    datetime {
      datetime
      isHwRunning
    }
  }
`

export const MutationDateSave = gql`
  mutation DateSave($date: ISODate!) {
    setDateTime(date: $date) {
      datetime
      isHwRunning
    }
  }
`

export const QueryDeviceTimeZoneOffset = gql`
  query DeviceTimeZoneOffset {
    deviceTimeZoneOffset
  }
`

export const QueryTimeZone = gql`
  query TimeZone {
    timezone {
      label
      tzCode
      offset
    }
  }
`

export const QueryTimeZones = gql`
  query TimeZones {
    timezones {
      label
      offset
      tzCode
    }
  }
`

export const MutationSetTimeZone = gql`
  mutation SetTimeZone($tzCode: String!) {
    setTimeZone(tzCode: $tzCode)
  }
`
