import { gql } from 'apollo-boost'

export const QueryCurrentUser = gql`
  query CurrentUser {
    currentUser {
      username
      token
    }
  }
`
