import {
  ApolloClient,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-boost'
import { ApolloLink, split } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { onError } from 'apollo-link-error'

import config from 'src/config'
import jsonSchema from 'src/schema/schema.json'

class SgClient {
  static getInstance() {
    if (!this.client) {
      this.client = new SgClient()
      return this.client.client
    }

    return this.client.client
  }

  constructor() {
    const httpLink = new HttpLink({
      uri: config.sgUri,
    })

    const wsLink = new WebSocketLink({
      uri: config.sgWsUri,
      options: {
        reconnect: true,
        connectionParams: () => ({
          authorization: localStorage.getItem('token') || null,
        }),
      },
    })

    const terminatingLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink,
    )

    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: localStorage.getItem('token') || null,
        },
      }))

      return forward(operation)
    })

    const errorLink = onError(({ graphQLErrors, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.map(error => {
          console.log({
            status: 'GraphQlError',
            message: error.message,
            operationName: operation.operationName,
            variables: operation.variables,
          })
          return false
        })
      }
    })

    const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData: jsonSchema,
    })

    const link = ApolloLink.from([authMiddleware, errorLink, terminatingLink])
    const cache = new InMemoryCache({ fragmentMatcher })

    this.client = new ApolloClient({
      link,
      cache,
    })
  }
}

export default SgClient
