/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE'

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const LOGOUT_USER = 'LOGOUT_USER'

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES'
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME'
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU'
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES'
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS'

export const APP_SET_VERSION = 'APP_SET_VERSION'
export const APP_GET_VERSION = 'APP_GET_VERSION'

export const GET_DEVICE_CONTROL = 'GET_DEVICE_CONTROL'
export const SET_DEVICE_CONTROL = 'SET_DEVICE_CONTROL'

export * from './menu/actions'
export * from './settings/actions'
export * from './auth/actions'
export * from './app/actions'
export * from './device/actions'
