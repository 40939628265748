import { gql } from 'apollo-boost'

export const QuerySensors = gql`
  query Sensors {
    sensors {
      id
      name
      shortName
      suffix
      min
      max
    }
  }
`

export const QueryGetSensorLoggerByDate = gql`
  query GetSensorLoggerByDate($input: GetSensorLoggerByDateInput!) {
    getSensorLoggerByDate(input: $input) {
      count
      data {
        id
        station
        sensor
        available
        valid
        createdTime
        value
      }
    }
  }
`

export const QueryGenerateSensorLoggerByDate = gql`
  query GenerateSensorLoggerByDate($input: GetSensorLoggerByDateInput!) {
    generateSensorLoggerByDate(input: $input)
  }
`
