import './assets/css/vendor/bootstrap.min.css'
import './assets/css/vendor/bootstrap.rtl.only.min.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './assets/css/common/sg.css'
import 'rc-switch/assets/index.css'
import config from 'src/config'
import { isUrl } from './helpers/Utils'

import 'moment/locale/ja'
import 'moment/locale/en-gb'

import {
  defaultColor,
  isDarkSwitchActive,
  isMultiColorActive,
  themeColorStorageKey,
} from './constants/defaultValues'

const color =
  (isMultiColorActive || isDarkSwitchActive) &&
  localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor

localStorage.setItem(themeColorStorageKey, color)
document.body.classList.add('rounded')

const query = new URLSearchParams(window.location.search)
const base64L = query.get('l')
const base64Wsl = query.get('wsl')

if (base64Wsl && base64L) {
  try {
    const l = atob(base64L)
    const wsl = atob(base64Wsl)
    if (isUrl(l) && isUrl(wsl)) {
      config.sgWsUri = wsl
      config.sgUri = l

      localStorage.setItem('l', base64L)
      localStorage.setItem('wsl', base64Wsl)
    }
  } catch (e) {
    console.log(e.message)
  }
} else {
  const base64L = localStorage.getItem('l')
  const base64Wsl = localStorage.getItem('wsl')
  if (base64L && base64Wsl) {
    const l = atob(base64L)
    const wsl = atob(base64Wsl)
    if (isUrl(l) && isUrl(wsl)) {
      config.sgWsUri = wsl
      config.sgUri = l
    }
  }
}

let render = () => {
  import('./assets/css/sass/themes/gogo.' + color + '.scss').then(x => {
    require('./AppRenderer')
  })
}
render()
