export default {
  sgUri: `http://${window.location.hostname}:4000/graphql`,
  sgWsUri: `ws://${window.location.hostname}:4000/graphql`,
  wifiServiceUri: `http://${window.location.hostname}:4001`,
}

// for smartgrobot.local
//export default {
//  sgUri: `http://smartgrobot.local:4000/graphql`,
//  sgWsUri: `ws://smartgrobot.local:4000/graphql`,
//  wifiServiceUri: `http://smartgrobot.local:4001`,
//}
