import { APP_SET_VERSION } from '../actions'

const INIT_STATE = {
  appVersion: '0.0.0',
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case APP_SET_VERSION: {
      return { ...state, appVersion: action.payload }
    }
    default: {
      return { ...state }
    }
  }
}
