import { gql } from 'apollo-boost'

export const sensorDetail = gql`
  fragment SensorDetailFragment on SensorDetail {
    id
    name
    shortName
    suffix
    min
    max
    ui {
      decimal
      scale
      suffix
      visible
    }
  }
`

export const SensorUITemplate = gql`
  fragment SensorUITemplateFragment on SensorUITemplate {
    id
    unit
    visible
    name
    decimal
    scale
    suffix
    min
    max
  }
`

export const StationData = gql`
  fragment StationDataFragment on StationData {
    id
    type
    name
    enable
    sensors {
      id
      stationId
      sensorId
      ui {
        ...SensorUITemplateFragment
      }
      status
      createdTime
      updatedTime
    }
    createdTime
    updatedTime
  }
  ${SensorUITemplate}
`
