export const defaultMenuType = 'menu-default'
export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

export const defaultLocale = 'en'
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'ja', name: '日本語', direction: 'ltr' },
]

export const searchPath = '/app/pages/search'
export const isMultiColorActive = false
export const defaultColor = 'light.greenlime'
export const defaultDirection = 'ltr'
export const isDarkSwitchActive = false
export const themeColorStorageKey = '__theme_color'
export const themeRadiusStorageKey = '__theme_radius'
