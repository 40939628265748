import { APP_SET_VERSION, APP_GET_VERSION } from '../actions'

export const setAppVersion = version => ({
  type: APP_SET_VERSION,
  payload: version,
})

export const getAppVersion = () => ({
  type: APP_GET_VERSION,
})
