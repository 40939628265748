import { combineReducers } from 'redux'
import settings from './settings/reducer'
import menu from './menu/reducer'
import authUser from './auth/reducer'
import app from './app/reducer'
import device from './device/reducer'

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  app,
  device,
})

export default reducers
