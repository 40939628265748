import { gql } from 'apollo-boost'

export const SubscriptionGpioStates = gql`
  subscription GpioStates {
    gpioStates {
      channel
      state
    }
  }
`

export const SubscriptionGpioTasks = gql`
  subscription GpioTasks {
    gpioTasks {
      channel
      uid
      type
      timeout
      until
    }
  }
`

export const SubscriptionGpioTask = gql`
  subscription GpioTask($index: Int!) {
    gpioTask(index: $index) {
      channel
      uid
      type
      timeout
      until
    }
  }
`
