import { GET_DEVICE_CONTROL, SET_DEVICE_CONTROL } from '../actions'

export const setDeviceControlInit = payload => ({
  type: SET_DEVICE_CONTROL,
  payload,
})

export const getDeviceControl = () => ({
  type: GET_DEVICE_CONTROL,
})
