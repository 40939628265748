import { gql } from 'apollo-boost'

export const MutationResetFactory = gql`
  mutation ResetFactory {
    resetFactory
  }
`

export const MutationMpuRestart = gql`
  mutation MpuRestart {
    mpuRestart
  }
`
