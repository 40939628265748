import { gql } from 'apollo-boost'

export const SubscriptionDeviceLog = gql`
  subscription DeviceLog {
    deviceLog {
      level
      message
      topic
      createdTime
    }
  }
`

export const MutationLogLevelSave = gql`
  mutation LogLevelSave($level: LogLevelEnum!) {
    logLevelSave(level: $level)
  }
`
