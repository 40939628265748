import { gql } from 'apollo-boost'

import { sensorDetail, SensorUITemplate, StationData } from './fragments'

export const QueryStation = gql`
  query Station($address: Int!) {
    station(address: $address) {
      type
      address
      lastSeen
      available
      sensors {
        id
        isValid
        value
      }
    }
  }
`

export const QueryStations = gql`
  query Stations {
    stations {
      type
      address
      lastSeen
      available
    }
  }
`

export const QueryStationData = gql`
  query StationData($address: Int!) {
    stationData(address: $address) {
      ...StationDataFragment
    }
  }
  ${StationData}
`

export const QueryAllStationData = gql`
  query AllStationData {
    allStationData {
      ...StationDataFragment
    }
  }
  ${StationData}
`

export const QueryLoggedStations = gql`
  query loggedStations {
    loggedStations {
      id
      type
      updatedTime
      createdTime
    }
  }
`

export const MutationStationSensorOrderSave = gql`
  mutation StationSensorOrderSave(
    $stationId: Int!
    $sensorId: Int!
    $order: Int!
  ) {
    stationSensorOrderSave(
      stationId: $stationId
      sensorId: $sensorId
      order: $order
    ) {
      ...StationDataFragment
    }
  }
  ${StationData}
`

export const MutationStationSensorUISave = gql`
  mutation StationSensorUISave(
    $stationId: Int!
    $sensorId: Int!
    $ui: UIInput!
  ) {
    stationSensorUISave(stationId: $stationId, sensorId: $sensorId, ui: $ui) {
      ...SensorUITemplateFragment
    }
  }
  ${SensorUITemplate}
`

export const MutationStationInfoSave = gql`
  mutation StationInfoSave($stationId: Int!, $info: StationInfoInput) {
    stationInfoSave(stationId: $stationId, info: $info) {
      ...StationDataFragment
    }
  }
  ${StationData}
`

export const SubscriptionStation = gql`
  subscription Station($address: Int!, $interval: Int) {
    station(address: $address, interval: $interval) {
      type
      address
      lastSeen
      available
      sensors {
        id
        isValid
        value
        sensorDetail {
          ...SensorDetailFragment
        }
      }
    }
  }
  ${sensorDetail}
`

export const SubscriptionStations = gql`
  subscription Stations {
    stations {
      type
      address
      lastSeen
      available
      sensors {
        id
        isValid
        value
      }
    }
  }
`
