import { gql } from 'apollo-boost'

export const QueryChannelLogger = gql`
  query ChannelLogger(
    $input: ChannelLoggerInput
    $after: ID
    $before: ID
    $limit: Int
  ) {
    channelLogger(input: $input) {
      count
      range(after: $after, before: $before, limit: $limit) {
        hasAfter
        hasBefore
        data {
          id
          createdTime
          channel
          state
          workingTime
        }
      }
    }
  }
`
