import { SET_DEVICE_CONTROL } from '../actions'

const INIT_STATE = {
  isControlInit: false,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DEVICE_CONTROL:
      return { ...state, isControlInit: action.payload }
    default:
      return { ...state }
  }
}
