import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
} from '../actions'

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
})
export const loginUserSuccess = user => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
})

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
})

export const logoutUser = () => ({
  type: LOGOUT_USER,
})
