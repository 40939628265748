import isNil from 'lodash/isNil'
import { all, fork, put, takeEvery, call } from 'redux-saga/effects'

import { setDeviceControlInit } from './actions'
import { GET_DEVICE_CONTROL } from '../actions'

import { NotificationManager } from '../../components/common/react-notifications'
import SgClient from '../../helpers/ApolloClient'
import { QueryChannel } from '../../graphql'

const getDeviceControlAsync = async address => {
  const client = SgClient.getInstance()
  const { data, errors } = await client.query({
    query: QueryChannel,
    variables: { index: address },
    errorPolicy: 'all',
  })

  if (!isNil(errors)) {
    throw errors
  }

  return data
}

const getAllDeviceControl = async () => {
  return [...Array(8).keys()].map(ch => {
    return getDeviceControlAsync(ch)
  })
}

function* getDeviceControl() {
  try {
    try {
      yield call(getAllDeviceControl)
    } catch (err) {
      console.error(err)
    }

    yield put(setDeviceControlInit(true))
  } catch (errors) {
    NotificationManager.error(
      errors[0].message,
      errors[0].extensions.code,
      3000,
      null,
      null,
      'filled',
    )
  }
}

export function* watchGetDeviceControl() {
  yield takeEvery(GET_DEVICE_CONTROL, getDeviceControl)
}

export default function* rootSaga() {
  yield all([fork(watchGetDeviceControl)])
}
