import isNil from 'lodash/isNil'
import { gql } from 'apollo-boost'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

import { loginUserSuccess } from './actions'
import { LOGIN_USER, LOGOUT_USER } from '../actions'

import { NotificationManager } from '../../components/common/react-notifications'
import SgClient from '../../helpers/ApolloClient'

const loginToLocalDeviceAsync = async (username, password) => {
  const client = SgClient.getInstance()
  const { data, errors } = await client.query({
    query: gql`
      query Login($username: String!, $password: String!) {
        login(username: $username, password: $password) {
          username
          token
        }
      }
    `,
    variables: { username, password },
    errorPolicy: 'all',
  })

  if (!isNil(errors)) {
    throw errors
  }

  return {
    username: data.login.username,
    token: data.login.token,
  }
}

function* loginToLocalDevice({ payload }) {
  const { username, password } = payload.user
  const { history } = payload
  try {
    const loginUser = yield call(loginToLocalDeviceAsync, username, password)

    localStorage.setItem('username', loginUser.username)
    localStorage.setItem('token', loginUser.token)
    yield put(loginUserSuccess(loginUser))
    history.push('/')
  } catch (errors) {
    NotificationManager.error(
      errors[0].message,
      errors[0].extensions.code,
      3000,
      null,
      null,
      'filled',
    )
  }
}

const logoutAsync = async () => {
  localStorage.removeItem('username')
  localStorage.removeItem('token')
  window.location.reload()
}

function* logout() {
  try {
    yield call(logoutAsync)
  } catch (error) {}
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginToLocalDevice)
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout)
}

export default function* rootSaga() {
  yield all([fork(watchLoginUser), fork(watchLogoutUser)])
}
