import { gql } from 'apollo-boost'
import * as fragments from './fragments'

export const QueryPreconditionTimer = gql`
  query PreconditionTimer($index: Int!) {
    preconditionTimer(index: $index) {
      index
      timers {
        start
        stop
      }
    }
  }
`

export const QueryPreconditionCriteria = gql`
  query PreconditionCriteria($index: Int!) {
    preconditionCriteria(index: $index) {
      index
      criteria {
        sensor {
          ...SensorDetailFragment
        }
        criteria
        greater
      }
    }
  }
  ${fragments.sensorDetail}
`

export const QueryPreconditionRange = gql`
  query PreconditionRange($index: Int!) {
    preconditionRange(index: $index) {
      index
      range {
        sensor {
          ...SensorDetailFragment
        }
        lower
        upper
      }
    }
  }
  ${fragments.sensorDetail}
`

export const MutationPreconditionCriteriaSave = gql`
  mutation PreconditionCriteriaSave(
    $index: Int!
    $criteria: PreconditionCriteriaInput!
  ) {
    preconditionCriteriaSave(index: $index, criteria: $criteria) {
      index
      writeOps
      criteria {
        sensor {
          ...SensorDetailFragment
        }
        criteria
        greater
      }
    }
  }
  ${fragments.sensorDetail}
`

export const MutationPreconditionRangeSave = gql`
  mutation PreconditionRangeSave(
    $index: Int!
    $range: PreconditionRangeInput!
  ) {
    preconditionRangeSave(index: $index, range: $range) {
      index
      writeOps
      range {
        sensor {
          ...SensorDetailFragment
        }
        lower
        upper
      }
    }
  }
  ${fragments.sensorDetail}
`

export const MutationPreconditionTimerSave = gql`
  mutation PreconditionTimerSave(
    $index: Int!
    $timers: [PreconditionTimerInput!]!
  ) {
    preconditionTimerSave(index: $index, timers: $timers) {
      index
      writeOps
      timers {
        start
        stop
      }
    }
  }
`
