import { gql } from 'apollo-boost'
import * as fragments from './fragments'

export const QuerySequence = gql`
  query Sequence {
    sequence {
      isActive
      control {
        type
      }
      preconditions {
        type
        value
      }
    }
  }
`

export const QuerySequenceOrder = gql`
  query SequenceOrder {
    sequenceOrder {
      channelOrderAndTiming {
        channel
        working
      }
    }
  }
`

export const QuerySequenceTypes = gql`
  query SequenceTypes {
    sequenceTypes
  }
`

export const QuerySequenceCriteria = gql`
  query SequenceCriteria {
    sequenceCriteria {
      criteria {
        sensor {
          ...SensorDetailFragment
        }
        criteria
        greater
        timing {
          waiting
          enable
        }
      }
    }
  }
  ${fragments.sensorDetail}
`

export const QuerySequenceRange = gql`
  query SequenceRange {
    sequenceRange {
      range {
        sensor {
          ...SensorDetailFragment
        }
        upper
        lower
        greater
        timing {
          waiting
          enable
        }
      }
    }
  }
  ${fragments.sensorDetail}
`

export const QuerySequenceTimer = gql`
  query SequenceTimer {
    sequenceTimer {
      timers {
        start
        stop
      }
    }
  }
`

export const QuerySequencePar = gql`
  query SequencePar {
    sequencePar {
      par {
        parSumInKJ
        timing {
          working
        }
      }
      sensor {
        id
        name
        shortName
        suffix
        min
        max
      }
    }
  }
`

export const MutationSequenceSave = gql`
  mutation SequenceSave(
    $control: SequenceControlInput!
    $preconditions: [PreconditionInput!]
  ) {
    sequenceSave(control: $control, preconditions: $preconditions) {
      writeOps
      isActive
      control {
        type
      }
      preconditions {
        type
        value
      }
    }
  }
`

export const MutationSequenceActivate = gql`
  mutation SequenceActivate($isActive: Boolean! = true) {
    sequenceActivate(isActive: $isActive) {
      writeOps
      isActive
    }
  }
`

export const MutationSequenceOrderSave = gql`
  mutation SequenceOrderSave(
    $channelOrderAndTiming: [ChannelOrderAndTimingInput!]!
  ) {
    sequenceOrderSave(channelOrderAndTiming: $channelOrderAndTiming) {
      writeOps
      channelOrderAndTiming {
        working
        channel
      }
    }
  }
`

export const MutationSequenceCriteriaSave = gql`
  mutation SequenceCriteriaSave($criteria: SequenceCriteriaInput!) {
    sequenceCriteriaSave(criteria: $criteria) {
      writeOps
      criteria {
        sensor {
          ...SensorDetailFragment
        }
        criteria
        greater
        timing {
          waiting
          enable
        }
      }
    }
  }
  ${fragments.sensorDetail}
`

export const MutationSequenceRangeSave = gql`
  mutation SequenceRangeSave($range: SequenceRangeInput!) {
    sequenceRangeSave(range: $range) {
      writeOps
      range {
        sensor {
          ...SensorDetailFragment
        }
        upper
        lower
        greater
        timing {
          waiting
          enable
        }
      }
    }
  }
  ${fragments.sensorDetail}
`

export const MutationSequenceTimerSave = gql`
  mutation SequenceTimerSave($timers: [SequenceTimerInput!]!) {
    sequenceTimerSave(timers: $timers) {
      writeOps
      timers {
        start
        stop
      }
    }
  }
`

export const MutationSequenceParSave = gql`
  mutation SequnceParSave($par: SequenceParInput!) {
    sequenceParSave(par: $par) {
      writeOps
      par {
        parSumInKJ
        timing {
          working
        }
      }
      sensor {
        id
        name
        shortName
        suffix
        min
        max
      }
    }
  }
`

export const SubscriptionSequenceState = gql`
  subscription sequenceState {
    sequenceState {
      preconditions {
        type
        isReachThreshold
        #        __typename
        ... on PreconditionCriteriaState {
          sensorValue
        }

        ... on PreconditionRangeState {
          sensorValue
        }

        ... on PreconditionTimerState {
          currentTimeInSecond
          currentIntervalTimerInSeconds {
            start
            stop
          }
          nextIntervalTimerInSeconds {
            start
            stop
          }
        }
      }
      control {
        type
        isReachThreshold
        ... on SequenceTimerState {
          currentTimeInSecond
          currentIntervalTimerInSeconds {
            start
            stop
          }
          nextIntervalTimerInSeconds {
            start
            stop
          }
        }
        ... on SequenceCriteriaState {
          sensorValue
          timing {
            criteriaState
            currentWorkingTimeInSecond
            currentWaitingTimeInSecond
          }
        }
        ... on SequenceRangeState {
          sensorValue
          timing {
            rangeState
            currentWorkingTimeInSecond
            currentWaitingTimeInSecond
          }
        }
        ... on SequenceParState {
          sensorValue
          currentParSumInKJ
          parSumInKJ
          parState
          currentWorkingTimeInSecond
        }
      }
    }
  }
`
