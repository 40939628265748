import isNil from 'lodash/isNil'
import { gql } from 'apollo-boost'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

import { setAppVersion } from './actions'
import { APP_GET_VERSION } from '../actions'

import { NotificationManager } from '../../components/common/react-notifications'
import SgClient from '../../helpers/ApolloClient'

const getAppVersionAsync = async () => {
  const client = SgClient.getInstance()
  const { data, errors } = await client.query({
    query: gql`
      query Version {
        version
      }
    `,
    errorPolicy: 'all',
  })

  if (!isNil(errors)) {
    throw errors
  }

  return data.version
}

function* getAppVersion() {
  try {
    const appVersion = yield call(getAppVersionAsync)
    localStorage.setItem('appVersion', appVersion)
    yield put(setAppVersion(appVersion))
  } catch (errors) {
    NotificationManager.error(
      errors[0].message,
      errors[0].extensions.code,
      3000,
      null,
      null,
      'filled',
    )
  }
}

export function* watchAppSetVersion() {
  yield takeEvery(APP_GET_VERSION, getAppVersion)
}

export default function* rootSaga() {
  yield all([fork(watchAppSetVersion)])
}
