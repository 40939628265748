import { gql } from 'apollo-boost'
import * as fragments from './fragments'

export const QueryChannel = gql`
  query Channel($index: Int!) {
    channel(index: $index) {
      index
      isActive
      control {
        type
        value
      }
      preconditions {
        type
        value
      }
    }
  }
`

export const QueryChannelControlTypes = gql`
  query ChannelControlTypes {
    channelControlTypes
  }
`

export const QueryChannelCriteria = gql`
  query ChannelCriteria($index: Int!) {
    channelCriteria(index: $index) {
      index
      criteria {
        sensor {
          ...SensorDetailFragment
        }
        criteria
        greater
        timing {
          enable
          working
          waiting
        }
      }
    }
  }
  ${fragments.sensorDetail}
`

export const QueryChannelRange = gql`
  query ChannelRange($index: Int!) {
    channelRange(index: $index) {
      index
      range {
        sensor {
          ...SensorDetailFragment
        }
        upper
        lower
        greater
        timing {
          enable
          working
          waiting
        }
      }
    }
  }
  ${fragments.sensorDetail}
`

export const QueryChannelTimer = gql`
  query ChannelTimer($index: Int!) {
    channelTimer(index: $index) {
      index
      mode
      timers {
        ... on ChannelTimerInterval {
          start
          stop
        }
        ... on ChannelTimerWorking {
          start
          working
        }
      }
    }
  }
`

export const QueryChannelPar = gql`
  query ChannelPar($index: Int!) {
    channelPar(index: $index) {
      index
      sensor {
        ...SensorDetailFragment
      }
      par {
        parSumInKJ
        timing {
          working
        }
      }
    }
  }
  ${fragments.sensorDetail}
`

export const MutationChannelActivate = gql`
  mutation ChannelActivate($index: Int!, $isActive: Boolean!) {
    channelActivate(index: $index, isActive: $isActive) {
      writeOps
      index
      isActive
    }
  }
`

export const MutationChannelSave = gql`
  mutation ChannelSave(
    $index: Int!
    $control: ChannelControlInput!
    $preconditions: [PreconditionInput!]
  ) {
    channelSave(
      index: $index
      control: $control
      preconditions: $preconditions
    ) {
      index
      writeOps
      isActive
      control {
        type
        value
      }
      preconditions {
        type
        value
      }
    }
  }
`

export const MutationChannelRangeSave = gql`
  mutation ChannelRangeSave($index: Int!, $range: ChannelRangeInput!) {
    channelRangeSave(index: $index, range: $range) {
      index
      writeOps
      range {
        sensor {
          ...SensorDetailFragment
        }
        upper
        lower
        greater
        timing {
          working
          waiting
          enable
        }
      }
    }
  }
  ${fragments.sensorDetail}
`

export const MutationChannelCriteriaSave = gql`
  mutation ChannelCriteriaSave($index: Int!, $criteria: ChannelCriteriaInput!) {
    channelCriteriaSave(index: $index, criteria: $criteria) {
      index
      writeOps
      criteria {
        sensor {
          ...SensorDetailFragment
        }
        criteria
        greater
        timing {
          enable
          working
          waiting
        }
      }
    }
  }
  ${fragments.sensorDetail}
`

export const MutationChannelTimerSave = gql`
  mutation ChannelTimerSave($index: Int!, $timers: [ChannelTimerInput!]!) {
    channelTimerSave(index: $index, timers: $timers) {
      index
      writeOps
      mode
      timers {
        ... on ChannelTimerInterval {
          start
          stop
        }
        ... on ChannelTimerWorking {
          start
          working
        }
      }
    }
  }
`

export const MutationChannelTimerWorkingSave = gql`
  mutation ChannelTimerWorkingSave(
    $index: Int!
    $timers: [ChannelTimerWorkingInput!]!
  ) {
    channelTimerWorkingSave(index: $index, timers: $timers) {
      index
      writeOps
      mode
      timers {
        ... on ChannelTimerInterval {
          start
          stop
        }
        ... on ChannelTimerWorking {
          start
          working
        }
      }
    }
  }
`

export const MutationChannelParSave = gql`
  mutation ChannelParSave($index: Int!, $par: ChannelParInput!) {
    channelParSave(index: $index, par: $par) {
      index
      par {
        parSumInKJ
        timing {
          working
        }
      }
    }
  }
`

export const SubscriptionChannelState = gql`
  subscription ChannelState($index: Int!, $interval: Int) {
    channelState(index: $index, interval: $interval) {
      index
      preconditions {
        type
        isReachThreshold
        #        __typename
        ... on PreconditionCriteriaState {
          sensorValue
        }

        ... on PreconditionRangeState {
          sensorValue
        }

        ... on PreconditionTimerState {
          currentTimeInSecond
          currentIntervalTimerInSeconds {
            start
            stop
          }
          nextIntervalTimerInSeconds {
            start
            stop
          }
        }
      }
      control {
        type
        isReachThreshold
        #        __typename
        ... on ChannelCriteriaState {
          sensorValue
          timing {
            criteriaState
            currentWorkingTimeInSecond
            currentWaitingTimeInSecond
          }
        }
        ... on ChannelTimerState {
          currentTimeInSecond
          mode
          currentIntervalTimerInSeconds {
            start
            stop
          }
          nextIntervalTimerInSeconds {
            start
            stop
          }
          currentWorkingTimerInSeconds {
            start
            working
          }
          nextWorkingTimerInSeconds {
            start
            working
          }
        }
        ... on ChannelRangeState {
          sensorValue
          timing {
            rangeState
            currentWorkingTimeInSecond
            currentWaitingTimeInSecond
          }
        }
        ... on ChannelParState {
          sensorValue
          sensorValue
          currentParSumInKJ
          parSumInKJ
          parState
          currentWorkingTimeInSecond
        }
      }
    }
  }
`
