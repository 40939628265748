import { gql } from 'apollo-boost'

export const QueryDeviceInfo = gql`
  query DeviceInfo {
    deviceInfo {
      deviceId
      name
      mcu {
        model
        version
        device_env
      }
      mpu {
        version
        env
      }
      network {
        ip
        mac
      }
      createdTime
      updatedTime
      startTime
    }
  }
`
export const QueryDeviceSetting = gql`
  query DeviceSetting {
    deviceSetting {
      id
      availableChannel
    }
  }
`

export const MutationSetDeviceSetting = gql`
  mutation setDeviceSetting($availableChannel: Int!) {
    setDeviceSetting(availableChannel: $availableChannel) {
      id
      availableChannel
    }
  }
`

export const QueryMcuInfo = gql`
  query DeviceInfo {
    deviceInfo {
      mcu {
        model
        version
        device_env
      }
    }
  }
`

export const QueryGetResource = gql`
  query GetResource {
    getResource {
      id
      createdTime
      Memory
      Cpu
    }
  }
`

export const MutationDeviceNameSave = gql`
  mutation deviceNameSave($name: String!) {
    deviceNameSave(name: $name) {
      deviceId
      name
    }
  }
`
