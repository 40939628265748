import { padStart } from 'lodash'
import { defaultDirection } from '../constants/defaultValues'

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key]
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1
    } else {
      return -1
    }
  })
  return array
}

export const getDateWithFormat = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 //January is 0!

  var yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return dd + '.' + mm + '.' + yyyy
}

export const getDirection = () => {
  let direction = defaultDirection
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction')
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  }
}

export const setDirection = localValue => {
  let direction = 'ltr'
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue
  }
  localStorage.setItem('direction', direction)
}

export const useParams = ({ location }) => {
  const { search } = location
  const matches = search.match(/([^?=&]+)(=([^&]*))?/gm)
  if (matches) {
    return matches
      .map(match => match.split('='))
      .reduce(
        (prev, [k, v]) => ({
          ...prev,
          [k]: v,
        }),
        {},
      )
  } else {
    return {}
  }
}

export const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

export const timeStringToMin = timeStr => {
  const [hour, min] = timeStr.split(':').map(value => parseInt(value))
  return hour * 60 + min
}

export const minToHHMM = _min => {
  const hour = Math.floor(_min / 60).toString()
  const min = Math.floor(_min % 60).toString()
  return `${padStart(hour, 2, '0')}:${padStart(min, 2, '0')}`
}

export const isUrl = str => {
  const pattern = new RegExp(
    '^((https?|wss?):\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator

  return pattern.test(str)
}

export const versionCompare = (v1, v2, options) => {
  const lexicographical = options && options.lexicographical
  const zeroExtend = options && options.zeroExtend
  let v1parts = v1.split('.')
  let v2parts = v2.split('.')

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x)
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push('0')
    while (v2parts.length < v1parts.length) v2parts.push('0')
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number)
    v2parts = v2parts.map(Number)
  }

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1
    }

    if (v1parts[i] === v2parts[i]) {
      continue
    } else if (v1parts[i] > v2parts[i]) {
      return 1
    } else {
      return -1
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1
  }

  return 0
}
