import { gql } from 'apollo-boost'

export const QueryConfiguration = gql`
  query Configuration {
    configuration {
      logLevel
    }
  }
`

export const SubscriptionDeviceStatus = gql`
  subscription DeviceStatus($interval: Int) {
    deviceStatus(interval: $interval) {
      heap
      bc
    }
  }
`

export const SubscriptionOnDeviceEvent = gql`
  subscription ONDeviceEvent($interval: Int) {
    onDeviceEvent(interval: $interval) {
      topic
      data
    }
  }
`
